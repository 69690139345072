import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'
import NewsletterForm from '../components/NewsletterForm'
import BookPromo from '../components/BookPromo'

function createURLFromSlugAndChapter(slug, chapter) {
  // insert the chapter number into a url that has this shape: '/stories/the-blue-flower/1/'
  const slugParts = slug.split('/')
  slugParts[3] = chapter
  return slugParts.join('/')
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const rootUrl = 'https://alexkondov.com'
    const socialImage =
      this.props.data.cover.childImageSharp.fluid.src
    const isFictionBlogPost =
      post.frontmatter.tags?.includes('fiction')

    const isFinalFictionalStory =
      isFictionBlogPost &&
      post.frontmatter.title === 'Broken Statues' &&
      post.frontmatter.chapter === 9

    const isFinalStoryChapter =
      !this.props.data.next?.fields.slug.includes(
        post.fields.slug.split('/')[2]
      )

    console.log(post)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            property="og:title"
            content={`${post.frontmatter.title} | ${siteTitle}`}
          />
          <meta property="og:description" content={post.excerpt} />
          <meta
            property="og:image"
            content={`${rootUrl}${socialImage}`}
          />
          <meta
            property="og:url"
            content={`${rootUrl}${post.fields.slug}`}
          />
          <meta
            name="twitter:card"
            content="summary_large_image"
          ></meta>
          <meta property="og:type" content="article" />
          <meta property="og:locale" content="en_US" />
          <link
            rel="canonical"
            href={`${rootUrl}${post.fields.slug}`}
          />
          <script
            async
            src="https://platform.twitter.com/widgets.js"
            charset="utf-8"
          ></script>
        </Helmet>
        <SEO
          title={post.frontmatter.title}
          description={post.excerpt}
        />
        {post.frontmatter.articleImage ? (
          <img
            className="post-image"
            src={post.frontmatter.articleImage}
            style={{
              objectFit: isFictionBlogPost ? 'contain' : 'cover',
            }}
          />
        ) : null}
        <h1 className="post-title" style={{ marginTop: 0 }}>
          {post.frontmatter.title}
        </h1>
        <p
          className="post-subtitle"
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        >
          {!isFictionBlogPost && (
            <>
              <span>{post.frontmatter.date} </span>
              <span className="meta-separator">• </span>
            </>
          )}
          {post.timeToRead} minute read
        </p>
        {isFictionBlogPost ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 40,
            }}
          >
            {Array.from({
              length: post.frontmatter.chapter_count,
            }).map((_, i) => (
              <>
                <Link
                  className="chapter-link"
                  href={createURLFromSlugAndChapter(
                    post.fields.slug,
                    i + 1
                  )}
                  style={{
                    margin: '0 3px',
                    fontSize:
                      i + 1 === post.frontmatter.chapter ? 22 : 16,
                    fontWeight:
                      i + 1 === post.frontmatter.chapter
                        ? 'bold'
                        : 'normal',
                  }}
                >
                  {convertToRomanNumeral(i + 1)}
                </Link>
                {i !== post.frontmatter.chapter_count - 1 && (
                  <div style={{ margin: '0 3px' }}>•</div>
                )}
              </>
            ))}
          </div>
        ) : null}
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        {isFictionBlogPost ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 40,
            }}
          >
            {Array.from({
              length: post.frontmatter.chapter_count,
            }).map((_, i) => (
              <>
                <Link
                  className="chapter-link"
                  href={createURLFromSlugAndChapter(
                    post.fields.slug,
                    i + 1
                  )}
                  style={{
                    margin: '0 3px',
                    fontSize:
                      i + 1 === post.frontmatter.chapter ? 22 : 16,
                    fontWeight:
                      i + 1 === post.frontmatter.chapter
                        ? 'bold'
                        : 'normal',
                  }}
                >
                  {convertToRomanNumeral(i + 1)}
                </Link>
                {i !== post.frontmatter.chapter_count - 1 && (
                  <div style={{ margin: '0 3px' }}>•</div>
                )}
              </>
            ))}
          </div>
        ) : (
          <>
            <NewsletterForm />
            <BookPromo
              image={
                this.props.data.bookCover.childImageSharp.fluid.src
              }
            />
          </>
        )}
      </Layout>
    )
  }
}

function convertToRomanNumeral(num) {
  const roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  }

  let numeral = ''

  Object.keys(roman).map((key) => {
    const q = Math.floor(num / roman[key])
    num -= q * roman[key]
    numeral += key.repeat(q)
  })

  return numeral
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    bookCover: file(
      absolutePath: { regex: "/tao-of-react-new-cover.png/" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    avatar: file(absolutePath: { regex: "/profile-pic-2.jpg/" }) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cover: file(absolutePath: { regex: "/cover.png/" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    storiesBookCover: file(
      absolutePath: { regex: "/stories-cover.png/" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        articleImage
        tags
        chapter
        chapter_count
      }
    }
    previous: markdownRemark(
      fields: { slug: { eq: $previousPostId } }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(fields: { slug: { eq: $nextPostId } }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
