import React from 'react'

export default function BookPromo({ image }) {
  return (
    <a href="https://taoofreact.com">
      <div className="book">
        <img style={{ borderRadius: 3 }} src={image} />
        <div>
          <h2 style={{ marginTop: 10, fontWeight: 400 }}>
            Tao of React
          </h2>
          <div className="divider"></div>
          <p>
            Learn proven practices about React application
            architecture, component design, testing and performance.
          </p>
        </div>
      </div>
    </a>
  )
}
